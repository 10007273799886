// $('.continue').prop('disabled', true);
$('.agree').change(function() {
$('.continue').prop('disabled', function(i, val) {
return !val;
}) }); 


// $('.btn_call').click(function() {
//   $('.agree').prop('checked', false);
//   $('.continue').prop('disabled', true);
// });


// $(".phone_input").mask("+7 (999) 999-9999");
// // $(".mail").mask("hhhhhh@hhhhhh");


$(".formsubmit1").submit(function() { return false; });

$(".formsubmit1").submit(function () {
        var th = $(this);
        $.ajax({
            type: "POST",
            url: "mail.php", //Change
            data: th.serialize()
        }).done(function () {
            // код после успешной отправки формы
            UIkit.modal('#success').show();
            setTimeout(function() {
              UIkit.modal('#success').hide();
              $('input').val('');
              $('.agree').removeAttr("checked");     
              $('.continue').prop('disabled', true);
            }, 3000);
        });
        return false;
});

$(".formsubmit2").submit(function() { return false; });

$(".formsubmit2").submit(function () {
        var th = $(this);
        $.ajax({
            type: "POST",
            url: "mail.php", //Change
            data: th.serialize()
        }).done(function () {
            // код после успешной отправки формы
            UIkit.modal('#success').show();
            setTimeout(function() {
              UIkit.modal('#success').hide();
              $('input').val('');
              $('.agree').removeAttr("checked");     
              $('.continue').prop('disabled', true);
            }, 3000);
        });
        return false;
});




   












